"use client";
import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add the event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <header
      className={`sticky top-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-50 max-w-screen-xl mx-5 lg:mx-auto py-4 sm:py-2 transition-colors duration-300 ${
        isScrolled ? "bg-white" : "bg-transparent"
      }`}
    >
      <nav
        className=" w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between"
        aria-label="Global"
      >
        <div className="flex items-center justify-between">
          <Link className="flex-none" href="/" aria-label="Brand">
            <img
              width={320}
              height={130}
              src="/images/jocologo.png"
              className="object-cover w-36 md:w-40"
            />
          </Link>
          <div className="block md:hidden md:order-none ">
            <div className="flex gap-2">
              <div className="">
                <img
                  src="/images/call.svg"
                  className="object-cover text-center"
                  width={26}
                  height={26}
                  alt="a polin Group Company"
                />
              </div>
              <div className="text-xl font-medium">+91 9167966966</div>
            </div>
          </div>

          {/* <div className="md:hidden order-3">
            <img
              src="/images/polin-logo.png"
              alt="a Polin Group Company"
              className="object-cover"
              width={180} // Adjust the width as needed
              height={30} // Adjust the height as needed
            />
          </div> */}
        </div>

        <div className="flex gap-12 font-medium justify-center mt-5 md:mt-0">
          <div className="transition duration-500 ease-in-out hover:translate hover:scale-110 font-semibold">
            <a href="/about-us/">ABOUT US</a>
          </div>
          <div className="transition duration-500 ease-in-out hover:translate hover:scale-110 font-semibold">
            <a href="/services/">SERVICES</a>
          </div>

          <div className="transition duration-500 ease-in-out hover:translate hover:scale-110 font-semibold">
            <a href="/contact-us/">CONTACT US</a>
          </div>
        </div>

        <div className="hidden md:block md:order-none ">
          <div className="flex gap-2">
            <div className="">
              <img
                src="/images/call.svg"
                className="object-cover text-center"
                width={26}
                height={26}
                alt="a polin Group Company"
              />
            </div>
            <div className="text-xl font-medium">+91 9167966966</div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
